<form *ngIf="authed" [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="default">
    <span bitDialogTitle
      >{{ "twoStepLogin" | i18n }}
      <span bitTypography="body1">{{ "authenticatorAppTitle" | i18n }}</span>
    </span>
    <ng-container bitDialogContent>
      <ng-container *ngIf="!enabled">
        <img class="float-right mfaType0" alt="Authenticator app logo" />
        <p bitTypography="body1">{{ "twoStepAuthenticatorDesc" | i18n }}</p>
        <p bitTypography="body1" class="tw-font-bold">
          1. {{ "twoStepAuthenticatorDownloadApp" | i18n }}
        </p>
      </ng-container>
      <ng-container *ngIf="enabled">
        <app-callout type="success" title="{{ 'enabled' | i18n }}" icon="bwi bwi-check-circle">
          <p bitTypography="body1">{{ "twoStepLoginProviderEnabled" | i18n }}</p>
          {{ "twoStepAuthenticatorReaddDesc" | i18n }}
        </app-callout>
        <img class="float-right mfaType0" alt="Authenticator app logo" />
        <p bitTypography="body1">{{ "twoStepAuthenticatorNeedApp" | i18n }}</p>
      </ng-container>
      <ul class="bwi-ul">
        <li>
          <i class="bwi bwi-li bwi-apple"></i>{{ "iosDevices" | i18n }}:
          <a
            bitLink
            href="https://itunes.apple.com/us/app/authy/id494168017?mt=8"
            target="_blank"
            rel="noreferrer"
            >Authy</a
          >
        </li>
        <li>
          <i class="bwi bwi-li bwi-android"></i>{{ "androidDevices" | i18n }}:
          <a
            bitLink
            href="https://play.google.com/store/apps/details?id=com.authy.authy"
            target="_blank"
            rel="noreferrer"
            >Authy</a
          >
        </li>
        <li>
          <i class="bwi bwi-li bwi-windows"></i>{{ "windowsDevices" | i18n }}:
          <a
            bitLink
            href="https://www.microsoft.com/p/authenticator/9wzdncrfj3rj"
            target="_blank"
            rel="noreferrer"
            >Microsoft Authenticator</a
          >
        </li>
      </ul>
      <p bitTypography="body1">{{ "twoStepAuthenticatorAppsRecommended" | i18n }}</p>
      <p *ngIf="!enabled" bitTypography="body1" class="tw-font-bold">
        2. {{ "twoStepAuthenticatorScanCode" | i18n }}
      </p>
      <hr *ngIf="enabled" />
      <p class="text-center" [ngClass]="{ 'mb-0': enabled }">
        <canvas id="qr"></canvas><br />
        <code appA11yTitle="{{ 'key' | i18n }}">{{ key }}</code>
      </p>
      <ng-container *ngIf="!enabled">
        <bit-form-field>
          <bit-label>3. {{ "twoStepAuthenticatorEnterCode" | i18n }}</bit-label>
          <input bitInput type="text" formControlName="token" appInputVerbatim />
        </bit-form-field>
      </ng-container>
    </ng-container>
    <ng-container bitDialogFooter>
      <button bitButton bitFormButton type="submit" buttonType="primary">
        {{ (enabled ? "disable" : "enable") | i18n }}
      </button>
      <button bitButton bitFormButton type="button" buttonType="secondary" [bitAction]="close">
        {{ "close" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
