<bit-dialog dialogSize="large" [loading]="loading">
  <span bitDialogTitle>
    {{ "manageSeats" | i18n }}
    <small class="tw-text-muted" *ngIf="clientName">{{ clientName }}</small>
  </span>
  <div bitDialogContent>
    <p>
      {{ "manageSeatsDescription" | i18n }}
    </p>
    <bit-form-field disableMargin>
      <bit-label>
        {{ "assignedSeats" | i18n }}
      </bit-label>
      <input id="assignedSeats" type="number" bitInput required [(ngModel)]="assignedSeats" />
      <bit-hint class="tw-text-muted" *ngIf="remainingOpenSeats > 0">
        <div class="tw-grid tw-grid-flow-col tw-gap-1 tw-grid-cols-1 tw-grid-rows-2">
          <span class="tw-col-span-1"
            >{{ unassignedSeats }} {{ "unassignedSeatsDescription" | i18n | lowercase }}</span
          >
          <span class="tw-col-span-1">0 {{ "purchaseSeatDescription" | i18n | lowercase }}</span>
        </div>
      </bit-hint>
    </bit-form-field>
  </div>
  <ng-container bitDialogFooter>
    <button
      type="submit"
      bitButton
      buttonType="primary"
      bitFormButton
      (click)="updateSubscription(assignedSeats)"
    >
      <i class="bwi bwi-refresh bwi-fw" aria-hidden="true"></i>
      {{ "save" | i18n }}
    </button>
    <button bitButton type="button" buttonType="secondary" bitDialogClose>
      {{ "cancel" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
