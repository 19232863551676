<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog>
    <span bitDialogTitle>{{ data.apiKeyTitle | i18n }}</span>
    <div bitDialogContent>
      <p bitTypography="body1">{{ data.apiKeyDescription | i18n }}</p>
      <app-user-verification-form-input formControlName="masterPassword" *ngIf="!clientSecret">
      </app-user-verification-form-input>
      <app-callout type="warning" *ngIf="clientSecret">{{ data.apiKeyWarning | i18n }}</app-callout>
      <app-callout
        type="info"
        title="{{ 'oauth2ClientCredentials' | i18n }}"
        icon="bwi bwi-key"
        *ngIf="clientSecret"
      >
        <p bitTypography="body1" class="tw-mb-1">
          <strong>client_id:</strong><br />
          <code>{{ clientId }}</code>
        </p>
        <p bitTypography="body1" class="tw-mb-1">
          <strong>client_secret:</strong><br />
          <code>{{ clientSecret }}</code>
        </p>
        <p bitTypography="body1" class="tw-mb-1">
          <strong>scope:</strong><br />
          <code>{{ data.scope }}</code>
        </p>
        <p bitTypography="body1" class="tw-mb-0">
          <strong>grant_type:</strong><br />
          <code>{{ data.grantType }}</code>
        </p>
      </app-callout>
    </div>
    <div bitDialogFooter>
      <button type="submit" buttonType="primary" *ngIf="!clientSecret" bitButton bitFormButton>
        <span>{{ (data.isRotation ? "rotateApiKey" : "viewApiKey") | i18n }}</span>
      </button>
      <button type="button" bitButton bitFormButton bitDialogClose>
        {{ "close" | i18n }}
      </button>
    </div>
  </bit-dialog>
</form>
