<app-header></app-header>
<bit-container>
  <ng-container *ngIf="!firstLoaded && loading">
    <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}"></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
  <app-subscription-status [providerSubscriptionResponse]="subscription"> </app-subscription-status>
  <ng-container>
    <div class="tw-flex-col">
      <strong class="tw-block tw-border-0 tw-border-b tw-border-solid tw-border-secondary-300 pb-2"
        >{{ "details" | i18n }} &#160;<span
          bitBadge
          variant="success"
          *ngIf="subscription.discountPercentage"
          >{{ "providerDiscount" | i18n: subscription.discountPercentage }}</span
        >
      </strong>
      <bit-table>
        <ng-template body>
          <ng-container *ngIf="subscription">
            <tr bitRow *ngFor="let i of subscription.plans">
              <td bitCell class="tw-pl-0 tw-py-3">
                {{ getFormattedPlanName(i.planName) }} {{ "orgSeats" | i18n }} ({{
                  i.cadence.toLowerCase()
                }}) {{ "&times;" }}{{ getFormattedSeatCount(i.seatMinimum, i.purchasedSeats) }}
                @
                {{
                  getFormattedCost(
                    i.cost,
                    i.seatMinimum,
                    i.purchasedSeats,
                    subscription.discountPercentage
                  ) | currency: "$"
                }}
              </td>
              <td bitCell class="tw-text-right tw-py-3">
                {{ ((100 - subscription.discountPercentage) / 100) * i.cost | currency: "$" }} /{{
                  "month" | i18n
                }}
                <div>
                  <bit-hint class="tw-text-sm tw-line-through">
                    {{ i.cost | currency: "$" }} /{{ "month" | i18n }}
                  </bit-hint>
                </div>
              </td>
            </tr>

            <tr bitRow>
              <td bitCell class="tw-pl-0 tw-py-3"></td>
              <td bitCell class="tw-text-right">
                <span class="tw-font-bold">Total:</span> {{ totalCost | currency: "$" }} /{{
                  "month" | i18n
                }}
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </bit-table>
    </div>
  </ng-container>
</bit-container>
